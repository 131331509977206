import React, { useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Mic } from "@mui/icons-material";
import { Box, Button, Grid, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: { xs: "87%", sm: "400px" },
  bgcolor: "#1a1a2e",
  boxShadow: 24,
  // p: { xs: 2, sm: 4 },
  borderRadius: 5,
  maxHeight: "100vh",
  overflowY: "auto",
};

const VoiceToText = (props) => {
  const {
    transcript,
    resetTranscript,
    listening,
    browserSupportsSpeechRecognition,
    finalTranscript,
  } = useSpeechRecognition();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [microphonePermission, setMicrophonePermission] = useState(null);

  const requestMicrophonePermission = async () => {
    if (!browserSupportsSpeechRecognition) {
      alert("Browser doesn't support speech recognition");
      return true;
    }
    try {
      const tt = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMicrophonePermission(true); // Permission granted
    } catch (error) {
      alert("No microphone connected to the browser");
      setMicrophonePermission(false); // Permission denied
    }
  };

  // Function to start listening when the mic is clicked
  const handleMicClick = (permission) => {
    if (permission == null) {
      // If permission status is unknown, request permission
      requestMicrophonePermission();
    } else if (permission == false) {
      // If permission is denied, alert the user
      alert("Please enable microphone access in your browser settings.");
    }
    if (permission) {
      // If permission is granted, start listening
      props?.onReset()
      setIsDone(false);
      setIsModalOpen(true);
      SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
    }
  };

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      alert("Browser doesn't support speech recognition");
    }
    requestMicrophonePermission();
  }, []);


  useEffect(() => {
    if (finalTranscript) {
      handleDone(finalTranscript);
    }
  }, [finalTranscript]);

  const handleDone = (text) => {
    setIsDone(true);
    SpeechRecognition.stopListening();
    setIsModalOpen(false);
    props?.onChange(text);
    resetTranscript();
  };

  return (
    <div>
      {/* Mic Icon to start voice recognition */}
      <Mic
        onClick={() => handleMicClick(microphonePermission)}
        id="micIcon"
        className="mic-icon bg-light"
      />

      {/* Modal for displaying listening state and transcribed text */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            onClick={() => setIsModalOpen(false)}
            className="ml-3 pt-2 pointer absolute animate-close"
            color="light"
            style={{
              top: "10px",
              right: "10px",
            }}
          />
          <Grid className="pb-20 pr-20 pl-20 pt-10">
            <div>
              {listening ? <h2>Listening...</h2> : <h2>Processing...</h2>}
              <p>{transcript || "Speak something to get transcribed."}</p>
              {transcript ? (
                <Grid container justifyContent={"space-between"}>
                  <Grid item>
                    <Button
                      style={{ marginBottom: "10px" }}
                      onClick={() => handleDone(transcript)}
                      className="text-selected bg-light rounded-btn pr-20 pl-20"
                    >
                      Done
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      style={{ marginBottom: "10px" }}
                      onClick={resetTranscript}
                      className="text-light bg-selected rounded-btn pr-20 pl-20"
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
            </div>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default VoiceToText;
