// src/SearchBar.js
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import "./_search-bar.scss";
import helpers from "../../../services/helpers";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  handleFilters,
  handleSearchBarFilters,
} from "../../../slices/frenziPartner";
import { DEFAULT_ADV_FILTER } from "../partnerHelper";
import { Mic } from "@mui/icons-material";
import VoiceToText from "./VoiceToText";

const placeHolderList = [
  "Suggest some feel-good movies",
  "TV Shows similar to Prison Break",
  "Show me some classic romantic comedies",
  "Recommend movies directed by Quentin Tarantino",
  "Srk and Deepika Movies",
];

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args); // Execute the function after the delay
    }, delay);
  };
}

const VoiceToTextComponent = React.memo((props) => {
  return <VoiceToText {...props} />;
});

const SearchBar = (props) => {
  const {
    suggestions = [],
    isLoading,
    isPartnerPage = false,
    isNative,
    input,
  } = props;
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [listIndex, stListIndex] = useState(0);
  const [placeholder, setPlaceholder] = useState(
    placeHolderList[listIndex].slice(0, 0)
  );
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const { advFilters, searchBarFilters } = useSelector(
    (state) => state.frenziPartner
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const suggestions1 = helpers.getSearchHistoryData()

  const suggestions1 = useMemo(() => {
    if (!isNative) {
      return helpers.getSearchHistoryData(input) || [];
    } else {
      return [];
    }
  }, [input]);

  useEffect(() => {
    if (!isNative) {
      const intr = setInterval(() => {
        setPlaceholder(placeHolderList[listIndex].slice(0, placeholderIndex));
        if (placeholderIndex + 1 > placeHolderList[listIndex].length) {
          setPlaceholderIndex(0);
          if (listIndex < placeHolderList.length - 1) {
            stListIndex(listIndex + 1);
          } else {
            stListIndex(0);
          }
        } else {
          setPlaceholderIndex(placeholderIndex + 1);
        }
      }, 120);
      return () => {
        clearInterval(intr);
      };
    }
  });

  const handleInputChange = (event) => {
    // props.setInput(event.target.value);
    // setOpen(event.target.value?.length > 0);
    props.onChange(event.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    props.setInput(suggestion);
    helpers.setSearchHistoryData(suggestion);
    navigate(`/partner/results?q=${suggestion}`);
    setOpen(false);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const isOpen =
    open &&
    ((suggestions1?.length > 0 && !isNative) ||
      (suggestions?.length > 0 && isNative && input.length));

  const inputHeight = inputRef.current?.offsetHeight
    ? inputRef.current.offsetHeight / 2
    : null;
  const radius = `${inputHeight}px` ? inputHeight : "25px";

  // const debouncedInputChange = debounce(handleInputChange, 1000);

  const handleAutoSearchClick = (item) => {
    setOpen(false);
    if (item.type == "media") {
      let url = helpers.getSearchDynamicLink(item);
      // navigate(url);
      window.open(url, "_blank");
    } else {
      handleFilter(item);
      navigate(`/advanced-search/results`);
    }
  };

  const handleFilter = (item) => {
    if (item.type == "person") {
      const castData = {
        ...item,
        name: item?.title,
        label: item?.title,
        value: item?.uuid,
      };
      dispatch(handleFilters({ ...advFilters, contributors: [castData] }));
    }
    if (item.type == "ott_platform") {
      dispatch(
        handleFilters({
          ...advFilters,
          ott_platforms: [{ ...item, ott_name: item.platform_name }],
        })
      );
    }
    if (item.type == "genre") {
      dispatch(handleFilters({ ...advFilters, genres: [item.genre] }));
    }
    if (item.type == "language") {
      dispatch(
        handleFilters({ ...advFilters, available_languages: [item.code] })
      );
    }
  };

  const handleUpdateFilter = (item) => {
    if (item.type == "person") {
      const castData = {
        ...item,
        name: item?.title,
        label: item?.title,
        value: item?.uuid,
      };
      if (
        advFilters.contributors.map((x) => x.value).includes(castData.value)
      ) {
        return true;
      }
      dispatch(
        handleFilters({
          ...advFilters,
          contributors: [...advFilters.contributors, castData],
        })
      );
    }
    if (item.type == "ott_platform") {
      if (advFilters.ott_platforms.map((x) => x.uuid).includes(item.uuid)) {
        return true;
      }
      dispatch(
        handleFilters({
          ...advFilters,
          ott_platforms: [
            ...advFilters.ott_platforms,
            { ...item, ott_name: item.platform_name },
          ],
        })
      );
    }
    if (item.type == "genre") {
      if (advFilters.genres.includes(item.genre)) {
        return true;
      }
      dispatch(
        handleFilters({
          ...advFilters,
          genres: [...advFilters.genres, item.genre],
        })
      );
    }
    if (item.type == "language") {
      if (advFilters.available_languages.includes(item.code)) {
        return true;
      }
      dispatch(
        handleFilters({
          ...advFilters,
          available_languages: [...advFilters.available_languages, item.code],
        })
      );
    }
  };

  const handleDeleteFilter = (item) => {
    if (item.type == "person") {
      let data = [...advFilters.contributors];
      const index = data.findIndex((x) => x?.uuid === item?.uuid);
      if (index !== -1) {
        data.splice(index, 1); // Removes 1 item at the found index
      }
      dispatch(handleFilters({ ...advFilters, contributors: [...data] }));
    }
    if (item.type == "ott_platform") {
      let data = [...advFilters.ott_platforms];
      const index = data.findIndex((x) => x?.uuid === item?.uuid);
      if (index !== -1) {
        data.splice(index, 1); // Removes 1 item at the found index
      }
      dispatch(handleFilters({ ...advFilters, ott_platforms: [...data] }));
    }
    if (item.type == "genre") {
      let data = [...advFilters.genres];
      const index = data.findIndex((x) => x == item?.genre);
      if (index !== -1) {
        data.splice(index, 1); // Removes 1 item at the found index
      }
      dispatch(handleFilters({ ...advFilters, genres: [...data] }));
    }
    if (item.type == "language") {
      let data = [...advFilters.available_languages];
      const index = data.findIndex((x) => x == item?.code);
      if (index !== -1) {
        data.splice(index, 1); // Removes 1 item at the found index
      }
      dispatch(
        handleFilters({ ...advFilters, available_languages: [...data] })
      );
    }
  };

  const handleOnMultiSearchChange = (values) => {
    // dispatch(handleFilters(DEFAULT_ADV_FILTER));
    props.onChange("");
    props.setInput("");
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1].type == "media"
    ) {
      let url = helpers.getSearchDynamicLink(values[values.length - 1]);
      window.open(url, "_blank");
    } else {
      const uniqueArray = Array.from(
        new Set(values.map((a) => JSON.stringify(a)))
      ).map((e) => JSON.parse(e));

      const removedItems = searchBarFilters.filter(
        (prevItem) =>
          !uniqueArray.some(
            (currItem) => JSON.stringify(currItem) === JSON.stringify(prevItem)
          )
      );

      dispatch(handleSearchBarFilters([...uniqueArray]));
      if (removedItems && removedItems.length) {
        handleDeleteFilter(removedItems[0]);
      } else {
        uniqueArray.map((x) => {
          handleUpdateFilter(x);
        });
      }
    }
  };

  const getOptions = (options) => {
    let result = [];
    if (options && options.length) {
      let result1 = options.map((x) => {
        return {
          ...x,
          label: x?.title || x?.platform_name || x?.genre || x?.language,
          value: x?.uuid,
        };
      });

      result = result1.filter((y) => {
        if (
          (y.type == "media" && !y.poster) ||
          (y.type == "person" && !y?.poster) ||
          (y.type == "genre" && !y?.genre_logo) ||
          (y.type == "language" && !y?.image) ||
          (y.type == "ott_platform" && !y?.platform_logo)
        ) {
          return false;
        } else {
          return true;
        }
      });
    }
    return result;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchBarFilters && searchBarFilters.length) {
        navigate(`/advanced-search/results`);
      } else if (e.target.value && e.target.value != "") {
        navigate(`/advanced-search/results?q=${e.target.value}`);
      }
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className="search-bar-container" sx={{ position: "relative" }}>
        {isNative ? (
          <>
            {/* auto multiple search start */}
            <Grid
              item
              flex={1}
              sx={{ mr: 1 }}
              className="native-searchbar relative"
            >
              <Autocomplete
                disablePortal
                id="autocomplete"
                value={searchBarFilters || []}
                defaultValue={"Asds"}
                onChange={(e, values) => handleOnMultiSearchChange(values)}
                onKeyDown={(e) => handleKeyDown(e)}
                onInputChange={(e) => {
                  handleInputChange(e);
                  props.setInput(e.target.value);
                }}
                loading={isLoading}
                options={getOptions(suggestions)}
                multiple={true}
                sx={{ width: "100%" }}
                size="medium"
                open={input && input != ""}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      placeholder="search by title, cast & crew, language, genre, platform"
                    />
                    {/* <VoiceToTextComponent
                      onReset={() => {
                        props.onChange("");
                        props.setInput("");
                      }}
                      onChange={(val) => {
                        props.onChange(val);
                        props.setInput(val);
                      }}
                    /> */}
                  </>
                )}
                renderOption={(props, option) => {
                  return (
                    <Grid
                      {...props}
                      container
                      style={{ alignItems: "center", background: "#fff" }}
                      className="p-10"
                    >
                      <Grid item style={{ width: 50, marginRight: 10 }}>
                        <Box
                          component="img"
                          className="d-block"
                          sx={{
                            height: "100%",
                            width: "100%",
                            maxHeight: { xs: 40, sm: 60 },
                            maxWidth: { xs: 20, sm: 40 },
                            borderRadius: "7px",
                            objectFit: "cover",
                            objectPosition: "top",
                          }}
                          alt={option?.name || "poster"}
                          src={
                            option?.poster ||
                            option?.genre_logo ||
                            option?.image ||
                            option?.platform_logo ||
                            helpers.getDefaultImage("trendingmedia")
                          }
                          // src={option?.poster ? helpers.getFullUrl(option?.poster, 300) : helpers.getDefaultImage('trendingmedia')}
                        />
                      </Grid>
                      <Grid item>
                        <Typography className="text-dark fw-500 lh-26">
                          {helpers.getSelectedText(
                            option?.label || "N/A",
                            input || ""
                          )}
                        </Typography>
                        <Grid container>
                          {option.type == "person" ? (
                            <p className="fw-300 fs-14 m-0 p-0 text-muted">
                              {option.department}
                            </p>
                          ) : option.type == "media" ? (
                            <p className="fw-300 fs-14 m-0 p-0 text-muted">
                              {option.media_type}
                              {option.release_year
                                ? " | " + option.release_year
                                : ""}
                              {option.language
                                ? " | " + option.language.toUpperCase()
                                : ""}
                            </p>
                          ) : (
                            <p className="fw-300 fs-14 m-0 p-0 text-muted">
                              {option.type.replace("_", " ")}
                            </p>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="mt-10">
                        <Divider />
                      </Grid>
                    </Grid>
                  );
                }}
              />
              {/* <VoiceToTextComponent
                onChange={(val) => {
                  props.onChange(val);
                  props.setInput(val);
                }}
              /> */}
            </Grid>
            {/* auto multiple search end */}
          </>
        ) : !isPartnerPage ? (
          <>
            <SearchIcon id="searchIcon" className="search-icon" />
            <input
              placeholder={
                isNative
                  ? "search by title, cast & crew, language, genre, platform"
                  : placeholder
              }
              value={input}
              ref={inputRef}
              onChange={(e) => {
                handleInputChange(e);
                props.setInput(e.target.value);
              }}
              onKeyUp={(e) => {
                props.onKeyUp(e);
                if (e.key === "Enter") {
                  setOpen(false);
                }
              }}
              onClick={() => setOpen(true)}
              className="seacrh-box-input"
              style={{
                borderTopLeftRadius: radius,
                borderTopRightRadius: radius,
                borderBottomLeftRadius: isOpen ? "0px" : radius,
                borderBottomRightRadius: isOpen ? "0px" : radius,
              }}
            />
            <VoiceToTextComponent
              onReset={() => {
                props.onChange("");
                props.setInput("");
              }}
              onChange={(val) => {
                props.onChange(val);
                props.setInput(val);
              }}
            />
          </>
        ) : (
          ""
        )}

        {isOpen ? (
          <div
            className="suggestion-container"
            style={{
              borderBottomLeftRadius: radius,
              borderBottomRightRadius: radius,
            }}
          >
            {isNative ? (
              <>
                {isLoading ? (
                  <>
                    <div className="suggestion-list-item">
                      <Grid container justifyContent={"center"}>
                        <Grid item>
                          <CircularProgress size={40} color="error" />
                        </Grid>
                      </Grid>
                    </div>
                  </>
                ) : (
                  <>
                    {suggestions.map((item, i) => {
                      if (
                        (item.type == "media" && !item.poster) ||
                        (item.type == "person" && !item?.poster) ||
                        (item.type == "genre" && !item?.genre_logo) ||
                        (item.type == "language" && !item?.image) ||
                        (item.type == "ott_platform" && !item?.platform_logo)
                      ) {
                        return true;
                      }
                      return (
                        <div className="suggestion-list-item" key={i}>
                          <div
                            key={i}
                            onClick={() => handleAutoSearchClick(item)}
                          >
                            {item.type == "media" || item.type == "person" ? (
                              <Grid
                                container
                                className="pl-20 pt-10 pointer ai-center"
                                alignItems={"center"}
                              >
                                <Grid
                                  xs={4}
                                  item
                                  className="h-50px mw-65 w-100 pr-20"
                                >
                                  <Box
                                    component="img"
                                    alt="poster"
                                    src={
                                      item.poster && item.poster !== ""
                                        ? helpers.getFullUrl(item.poster)
                                        : helpers.getDefaultImage(
                                            item.type ? item.type : "search"
                                          )
                                    }
                                    sx={{
                                      height: "65px",
                                      width: "50px",
                                      borderRadius: "7px",
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography className="text-dark fw-500 lh-26">
                                    {helpers.getSelectedText(
                                      item?.title ? item?.title : "",
                                      input
                                    )}
                                  </Typography>
                                  <Grid container>
                                    <p className="fw-300 fs-14 m-0 p-0 text-muted">
                                      {item.media_type
                                        ? item.media_type
                                        : item.department
                                        ? item.department
                                        : ""}
                                    </p>
                                    {item?.release_date && (
                                      <>
                                        <span className="w-300 fs-14 m-0 p-0 text-muted">
                                          &nbsp;|&nbsp;
                                        </span>
                                        <p className="fw-300 fs-14 m-0 p-0 text-muted">
                                          {new Date(
                                            item.release_date
                                          ).getFullYear()}
                                        </p>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid
                                container
                                className="pl-20 pt-10 pointer ai-center"
                                alignItems={"center"}
                              >
                                <Grid
                                  xs={4}
                                  item
                                  className="h-50px mw-65 w-100 pr-20"
                                >
                                  <Box
                                    component="img"
                                    alt="poster"
                                    src={
                                      item?.genre_logo ||
                                      item?.image ||
                                      item?.platform_logo ||
                                      helpers.getDefaultImage("search")
                                    }
                                    sx={{
                                      height: "65px",
                                      width: "50px",
                                      borderRadius: "7px",
                                      objectFit: "contain",
                                      backgroundColor: "#000",
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography className="text-dark fw-500 lh-26">
                                    {helpers.getSelectedText(
                                      item?.genre ||
                                        item?.language ||
                                        item?.platform_name ||
                                        "N/A",
                                      input
                                    )}
                                  </Typography>
                                  <Grid container>
                                    <p className="fw-300 fs-14 m-0 p-0 text-muted">
                                      {item.type.replace("_", " ")}
                                    </p>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                            <Grid container className="pl-20 pr-20">
                              <Box className="w-100">
                                <Divider />
                              </Box>
                            </Grid>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            ) : (
              <>
                {suggestions1.map((x, i) => {
                  return (
                    <div
                      onClick={() => handleSuggestionClick(x?.query)}
                      className="suggestion-list-item"
                      key={i}
                    >
                      <p className="p-0 m-0" style={{ color: "#000" }}>
                        {x?.query}
                      </p>
                    </div>
                  );
                })}
              </>
            )}
            {/* {suggestions.map((x, i) => {
              return (
                <div className="suggestion-list-item" key={i}>
                  <Link onClick={() => {
                    if (x.media_type === 'movie' || x.media_type === 'tv') {
                      helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', props?.refPage);
                    } else if (x.watchlist_id && x.watchlist_id !== "") {
                      helpers.watchlistClick(x?.category_name || x?.watchlist, x?.watchlist_id, props?.refPage);
                    }
                  }} to={helpers.getDynamicLink(x)}>
                    <Grid container style={{ alignItems: 'center' }}>
                      <Grid item>
                        <Box
                          component="img"
                          className='d-block'
                          sx={{
                            // height: 40,
                            width: 30,
                            // maxHeight: { xs: 30, sm: 40 },
                            maxWidth: { xs: 20, sm: 30 },
                            borderRadius: "7px",
                            objectFit: 'cover',
                            objectPosition: 'top',
                          }}
                          alt={x?.title || 'poster'}
                          src={x?.poster_path ? helpers.getFullUrl(x?.poster_path, 300) : helpers.getDefaultImage('trendingmedia')}
                        />
                      </Grid>
                      <Grid className="pl-10">
                        <p className="p-0 m-0" style={{ color: '#000' }}>{x?.title}</p>
                      </Grid>
                    </Grid>
                  </Link>
                </div>
              );
            })} */}
          </div>
        ) : (
          ""
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SearchBar;
